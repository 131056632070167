<div class="borderLine">
  <div class="markets">
    <div class="markets-regionHeading">
      Countries & Regions
    </div>
    <div class="markets-languageHeading">
      Language
      <i (click)="onClose()" class="Vfs-close material-icons paddingTop">close</i>
    </div>
  </div>
  <div style="display: flex;" class="regions">
    <div style="width:50%" class="stickyBox-locale">
      <ul class="regions-region" *ngFor="let list of defaultRegionList; let i = index">
        <li
          *ngIf="list.id !== 'EU' && list.id !== 'UK' && list.id !== 'NS' && list.id !== 'TX'"
          (click)="setAutoFlag();fetchLanguages(list);"
          [ngClass]="list.isSelected === true ? 'showSelection' : 'hideSelection'"
        >
          {{ list.label }}
        </li>
        <li
          *ngIf="list.id === 'UK'"
          (click)="UK(list)"
          [ngClass]="list.isSelected === true ? 'showSelection' : 'hideSelection'"
        >
          <a href="{{ukwebsite}}" target="_blank">{{ list.label }}</a>
        </li>
        <li
          *ngIf="list.id === 'NS'"
          (click)="UK(list)"
          [ngClass]="list.isSelected === true ? 'showSelection' : 'hideSelection'"
        >
          <a href="{{nswebsite}}" target="_blank">{{ list.label }}</a>
        </li>
        <li
          *ngIf="list.id === 'TX'"
          (click)="UK(list)"
          [ngClass]="list.isSelected === true ? 'showSelection' : 'hideSelection'"
        >
          <a href="{{txwebsite}}" target="_blank">{{ list.label }}</a>
        </li>
        <div *ngIf="list.id === 'EU'">
          <mat-accordion class="VolvoAccordion">
            <mat-expansion-panel [expanded]="isExpand">
              <mat-expansion-panel-header (click)="fetchCountries(list)" class="disable_ripple" [ngClass]="list.isSelected === true ? 'showSelection' : 'hideSelection'">
                <li  >
                  {{ list.label }}
                </li>
              </mat-expansion-panel-header>
              <div *ngIf="showCountries" class="regions-list">
                <div class="regions-alphabets">A</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'A'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">B</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'B'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">C</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'C'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>

                <div class="regions-alphabets">D</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'D'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">E</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'E'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">F</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'F'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">G</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'G'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">H</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'H'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>

                <div class="regions-alphabets">I</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'I'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">L</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'L'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">M</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'M'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">N</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'N'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">P</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'P'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">R</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'R'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
                <div class="regions-alphabets">S</div>
                <div>
                  <ul *ngFor="let list of countryObj.list | sortBy: 'asc':'label':'S'">
                    <li id={{list.id}}
                      (click)="fetchEuropeLang(list)"
                      [ngClass]="list.isSelected === true ? 'showCSelection' : 'hideCSelection'"
                    >
                      {{ list.label }}
                    </li>
                  </ul>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ul>
    </div>

    <div *ngIf="showLanguage" class="languages stickyBox-locale" style="width:49%">
      <div class="languages-spoken">
        <ul *ngFor="let lang of langList">
          <li id={{lang.label}}
            (click)="onLangChange(lang.id)"
            [ngClass]="lang.isSelected === true ? 'showLanguageSelected' : 'background:none'"
          >
            {{ lang.label }}
          </li>
        </ul>
      </div>
      <div class="languages-all">
        <ul *ngFor="let lang of allLangList | sortBy: 'asc':'label'">
          <li id={{lang.label}}
            (click)="onLangChange(lang.id)"
            [ngClass]="lang.isSelected === true ? 'showLanguageSelected' : 'background:none'"
          >
            {{ lang.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
