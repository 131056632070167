<div class="tabMobSidePadding">
    <section class="contactUs">
        <div class="contactUs-title">
            <span class="contactUs-title-titleTeam">
                {{ "uk/pages/contact/contactTitle" | lowercase | translate }}
            </span>
        </div>
        <div class="contactUs-title">
            <span class="contactUs-title-intro">
                {{ region===regionEnum.UK?("uk/pages/contact/contactIntro" | lowercase | translate):("uk/pages/contact/ns/contactIntro" | lowercase | translate) }}
            </span>
        </div>
        <div class="contactUs-title">
            <span class="contactUs-title-intro">
                {{ region===regionEnum.UK?("uk/pages/contact/contactIntro1" | lowercase | translate):("uk/pages/contact/ns/contactIntro1" | lowercase | translate) }}
            </span>
        </div>
        <div class="contactUs-title topP" *ngIf="region===regionEnum.UK">
            <div class="contactUs-title-intro">
                <span class="contactUs-title-sales"> {{ "uk/pages/contact/contactSales" | lowercase | translate }}</span>
            </div>
            <div class="contactUs-title-intro">
                {{ "uk/pages/contact/contactPhone" | lowercase | translate }}:
                <span><a href="tel:+44 (0)844 579 1198">+44 (0)844 579 1198</a></span>
            </div>
            <div class="contactUs-title-intro">
                {{ "uk/pages/contact/contactEmail" | lowercase | translate }}:
                <span><a href="mailto:usedtrucks@volvo.com">usedtrucks@volvo.com</a></span>
            </div>
        </div>
    </section>
    <section class="contactUs">
        <div *ngIf="region===regionEnum.UK">
            <div class="contactUs-title">
                <span class="contactUs-title-salesType"> {{ "uk/components/footer/remainingWarranty" | lowercase | translate }}, {{ "uk/components/footer/volvoSelected" | lowercase | translate }} and {{ "uk/pages/contact/contactSalesType" | lowercase | translate }}</span>
                <span class="contactUs-title-mobileSalesType"> {{ "uk/components/footer/remainingWarranty" | lowercase | translate }} / {{ "uk/components/footer/volvoSelected" | lowercase | translate }} {{"uk/pages/contact/and" | lowercase | translate}} {{ "uk/pages/contact/approved" | lowercase | translate }}</span>
            </div>
        </div>
        <div class="contactUs-title topPadding">
            <div>
                <volvo-sales-person [salesTeamData]="salesTeamDetails"></volvo-sales-person>
            </div>
        </div>
    </section>

    <section class="contactUs">
        <div *ngIf="region===regionEnum.UK">
            <div class="contactUs-title">
                <span class="contactUs-title-salesTypeOther"> {{ "uk/pages/contact/salesEconomy" | lowercase | translate }}</span>
            </div>
        </div>
        <div class="contactUs-title topPadding">
            <div>
                <volvo-sales-person [salesTeamData]="salesTeamEconomyDetails"></volvo-sales-person>
            </div>
        </div>
    </section>
    <section class="contactUs">
        <div *ngIf="region===regionEnum.UK">
            <div class="contactUs-title">
                <span class="contactUs-title-salesTypeOther"> {{ "uk/pages/contact/contactManagement" | lowercase | translate }}</span>
            </div>
        </div>
        <div class="contactUs-title topPadding">
            <div>
                <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
            </div>
        </div>
    </section>

    <section class="bottomPadding">
        <volvo-centre-navlink id="centreNavLinks"></volvo-centre-navlink>
    </section>
</div>
    <div class="mobile">
        <volvo-footer></volvo-footer>
    </div>
