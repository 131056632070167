<section class="volvoConnect">
    <div class="tabMobSidePadding">
        <div class="volvoConnect-title">
            <span>{{ "uk/components/footer/volvoconnect" | lowercase | translate }}</span>
        </div>
        <div class="volvoConnect-title1">
            <span>{{ "uk/pages/services/volvoconnect/title" | lowercase | translate }}</span>
        </div>
        <div class="volvoConnect-text">
            <span>{{"uk/pages/services/volvoconnect/text1" | lowercase | translate}}</span>
        </div>
        <div class="volvoConnect-list">
            <drag-scroll #slider class="drag-scroll-style" (reachesLeftBound)="leftBoundStat($event)" (reachesRightBound)="rightBoundStat($event)">
                <div drag-scroll-item *ngFor="let list of volvoConnectList" class="volvoConnect-dragScroll">
                    <div class="volvoConnect-list-listItem volvoConnect-list-marginRight48">
                        <div>
                            <img [src]="list.url" />
                        </div>
                        <div class="volvoConnect-list-title">
                            {{ list.title | translate }}
                        </div>
                        <div class="volvoConnect-list-text" [innerHTML]="list.text| translate"></div>
                    </div>
                </div>
            </drag-scroll>
            <div class="volvoConnect-dragScrollbtn">
                <button (click)="slider.moveLeft()" [disabled]="leftNavDisabled" class="volvoConnect-sliderButton volvoConnect-moveLeft">
                    <i class="fal fa-chevron-left"></i>
                </button>
                <button (click)="slider.moveRight()" [disabled]="rightNavDisabled" class="volvoConnect-sliderButton volvoConnect-right">
                    <i class="fal fa-chevron-right"></i>
                </button>
            </div>

        </div>
        <div class="volvoConnect-img__wrap">
            <img [src]="assetService.getAssetUrl('assets/pages/services/volvo-connect-truck.webp')" class="volvoConnect-img__width" />
            <div class="volvoConnect-img__description" [innerHTML]=" 'uk/pages/services/volvoconnect/hoverText' | lowercase | translate">
            </div>
            <div class="volvoConnect-displayMobile" [innerHTML]=" 'uk/pages/services/volvoconnect/hoverText' | lowercase | translate">
            </div>
        </div>
        <div class="volvoConnect-text2">
            <span>{{ "uk/pages/services/volvoconnect/text2" | lowercase | translate }}</span>
        </div>
        <div class="volvoConnect-list volvoConnect-list-connectList volvoConnect-display">
            <div *ngFor="let list of volvoConnectImgList;let i=index;" style="padding: 48px 0 60px;">
                <div class="volvoConnect-list-listItem volvoConnect-marginRight30" *ngIf="i===0 || i===2">
                    <div>
                        <img [src]="list.url" class="volvoConnect-list-connectList-connectImage" />
                    </div>
                    <div class="volvoConnect-list-title">
                        {{ list.title | translate }}
                    </div>
                    <div class="volvoConnect-list-text" [innerHTML]="list.text| translate"></div>
                </div>
                <div class="volvoConnect-list-listItem" *ngIf="i===1 || i===3">
                    <div>
                        <img [src]="list.url" class="volvoConnect-list-connectList-connectImage" />
                    </div>
                    <div class="volvoConnect-list-title">
                        {{ list.title | translate }}
                    </div>
                    <div class="volvoConnect-list-text" [innerHTML]="list.text| translate"></div>
                </div>
            </div>
        </div>
        <div class="volvoConnect-list volvoConnect-displayMobile">
            <drag-scroll #sliderConnet class="drag-scroll-style" (reachesLeftBound)="leftBoundStatVolvoConnect($event)" (reachesRightBound)="rightBoundStatVolvoConnect($event)">
                <div drag-scroll-item *ngFor="let list of volvoConnectImgList; let i = index" class="volvoConnect-dragScroll">
                    <div class="volvoConnect-list-listItem">
                        <div>
                            <img [src]="list.url" />
                        </div>
                        <div class="volvoConnect-list-title">
                            {{ list.title | translate }}
                        </div>
                        <div class="volvoConnect-list-text" [innerHTML]="list.text| translate"></div>
                    </div>
                </div>
            </drag-scroll>
            <div class="volvoConnect-dragScrollbtn">
                <button (click)="sliderConnet.moveLeft()" [disabled]="leftNavDisabledVolvoConnect" class="volvoConnect-sliderButton volvoConnect-moveLeft">
                    <i class="fal fa-chevron-left"></i>
                </button>
                <button (click)="sliderConnet.moveRight()" [disabled]="rightNavDisabledVolvoConnect" class="volvoConnect-sliderButton volvoConnect-right">
                    <i class="fal fa-chevron-right"></i>
                </button>
            </div>
        </div>
        <div class="volvoConnect-text3">
            <span>{{ "uk/pages/services/volvoconnect/text3" | lowercase | translate }}</span>
        </div>
        <div class="volvoConnect-text3">
            <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
        </div>
        </div>
</section>
<volvo-footer class="footer"></volvo-footer>
