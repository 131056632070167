import { Component, OnInit, Input } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import { HtmlRoutePages } from '../../../app-routing.model';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})
export class WhoWeAreComponent implements OnInit {
  region:string;
  imageUrl:string;
  regionEnum=regionEnum;
  constructor(private translateService: TranslateService,private metaTagService: Meta,
    private regionSettingService:RegionalSettingsService,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService) {
    this.regionSettingService.regionalSettings$.subscribe(r => {
      this.region = r.regionsSettings[0].region;
      
    });
   }

  ngOnInit(): void {
    console.log(this.region);
    switch(this.region)
    {
      case this.regionEnum.UK:this.imageUrl = "assets/pages/aboutUs/About us image.webp";break;
      case this.regionEnum.NS:this.imageUrl = "assets/pages/aboutUs/About us image NS.webp";break;
      case this.regionEnum.TX:this.imageUrl = "assets/pages/aboutUs/AboutusimageTX.webp";break;
    }
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/aboutus/whoweare/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/aboutus/whoweare/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/aboutus/whoweare/altImage'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('About Us' , 'Who we are', HtmlRoutePages.aboutUs);
  }
}
