<div class="tabMobSidePadding">
    <volvo-contact-skeleton *ngIf="isLoading"></volvo-contact-skeleton>
    <section class="truckCentre">
        <div class="truckCentre tabMobHeroMargin">
            <img [hidden]="isLoading" (load)="onImageLoad()" (error)="onError()" [src]="region===regionEnum.UK?'../../../assets/pages/contactus/Bardon.webp':'../../../assets/pages/contactus/ns/glas.webp'" class="truckCentre-centreImg">
        </div>
    </section>
    <div [hidden]="isLoading">
        <section class="banburryCentre">
            <div class="banburryCentre-title">
                <h1>
                    {{region===regionEnum.UK?("uk/pages/contact/bardonTruckCentre" | lowercase | translate) : ("uk/pages/contact/glasgowTruckCentre" | lowercase | translate)}}
                </h1>
            </div>
            <div class="banburryCentre-text" [innerHTML]="region===regionEnum.UK?('uk/pages/contact/bardonText' | lowercase | translate):('uk/pages/contact/glasgowCentreText' | lowercase | translate)">
            </div>
        </section>
        <section class="banburryCentre">
            <div class="banburryCentre-flex">
                <div class="banburryCentre-salesTeam">
                    <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
                </div>
                <div class="banburryCentre-map">
                    <volvo-map-centre [centre]="mapCentre"></volvo-map-centre>
                </div>
            </div>
        </section>
    </div>
    </div>
    <volvo-footer></volvo-footer>
