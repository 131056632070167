<div *ngIf="printService" [class.isPrinting]="printService.isPrinting"></div>
<div #mapContainer class="Map"></div>

<mat-sidenav-container>
  <mat-sidenav #menu mode="over" class="Menu AsideScroller" position="end">
    <div style="display:flex;justify-content: space-between;">
      <div class="Menu-title">
        Menu
      </div>
      <div class="Menu-close">
        <i (click)="menu.close()" class="material-icons">close</i>
      </div>
    </div>
    <hr class="Menu-hr" />
    <a (click)="menu.close()" class="Menu-link" routerLinkActive="Active" data-cy="app-navlinks" [routerLink]="HtmlRoutePages.home | localize">
      Home
    </a>
    <div *ngFor="let link of navLinks">
      <div>
        <a (click)="link.children?ActivateRoute(link):menu.close()" class="Menu-link" routerLinkActive="Active" data-cy="app-navlinks"
          [routerLink]="link.children?undefined:link.path | localize" (click)="logTruckModelButtonClick(link.path,link.label)"> 
          {{ link.label | lowercase | translate }}
          <img *ngIf="link.children" [ngClass]="link.isActive?'Menu-link-imgIsActive':'Menu-link-imgInActive'" src="/assets/pages/truckModel/right.png"
          />
        </a>
      </div>
      <div *ngIf="link.isActive">
        <div *ngFor="let sublink of link.children">
          <a (click)="menu.close()" class="Menu-link Menu-link-paddingleft" [routerLink]="sublink.path | localize" routerLinkActive="Active"
            data-cy="app-navlinks" *ngIf="sublink.label !== 'pages/truckModel/volvoFE' && sublink.label !== 'pages/truckModel/volvoFL' && sublink.label !== 'uk/pages/aboutus/whatwehave'"  (click)="logTruckModelButtonClick(sublink.path,sublink.label)">
            {{ sublink.label | lowercase | translate }}
          </a>
          <a (click)="menu.close()" class="Menu-link Menu-link-paddingleft" [href]="sublink.path | localize" routerLinkActive="Active" target="_blank"
            data-cy="app-navlinks" *ngIf="sublink.label === 'pages/truckModel/volvoFE' || sublink.label === 'pages/truckModel/volvoFL'" (click)="logTruckModelButtonClick(sublink.path,sublink.label)">
            {{ sublink.label | lowercase | translate }}
          </a>
        </div>
      </div>
    </div>

    <hr class="Menu-hr" />

    <!-- <a class="Menu-link Menu-link--withIcon">
            <volvo-phone phone="+46 31-440 900"></volvo-phone>
        </a>
        <a class="Menu-link Menu-link--withIcon" (click)="onMailClick()">
            <i class="fas fa-envelope"></i>
            <span [innerHTML]="'components/app/sendEmail' | lowercase | translate"></span>
        </a>
        <a class="Menu-link Menu-link--withIcon">
            <i class="fas fa-map-marker-alt"></i>
            <span [innerHTML]="'components/app/address' | lowercase | translate"></span>
        </a> -->

    <!-- <hr class="Menu-hr"> -->

    <div class="Menu-language" *isSelectedSite>
      <volvo-language-select></volvo-language-select>
    </div>
  </mat-sidenav>

  <mat-sidenav #product (openedChange)="onTruckSidenavToggle($event)" mode="over" class="isProduct AsideScroller">
    <ng-container *ngIf="isSideNavOpen; else loading">
      <volvo-truck-page *ngIf="activeTruck?._id" [id]="activeTruck._id"></volvo-truck-page>
    </ng-container>

    <ng-template #loading>
      <volvo-loading></volvo-loading>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav-content [class.isPrinting]="printService.isPrinting">
    <main class="Main">
      <div id="PrintHeader">
        <volvo-header (showBreadCrumbs)=showorHideBreadCrumbs($event)></volvo-header>
      </div>
      <div>
        <div>
            <app-ng-dynamic-breadcrumb [ngClass]="showBreadCrumbs? 'breadCrumbDiv' : 'breadCrumbHome'" [symbol]="'  '"></app-ng-dynamic-breadcrumb>
              <!-- <volvo-breadcrumb [ngClass]="showBreadCrumbs? 'breadCrumbDiv' : 'breadCrumbHome'"></volvo-breadcrumb> -->
          </div>
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<router-outlet name="print"></router-outlet>
